window.addEventListener("load", function () {
  var homeHeaderContainer = document.querySelectorAll(".page-header__home__carousel");
  if (1 < homeHeaderContainer.length) {
    var homeHeaderCarousel = new Swiper(".page-header__home__carousel", {
      speed: 400,
      direction: "horizontal",
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true
      }
    });
  }
});